:root {
  --mdc-theme-primary: #1904ff;
  --mdc-theme-secondary: #1e1e44;
  --mdc-theme-on-surface: #eaf2fd;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}

:root {
  --mdc-theme-primary: #1904ff;
  --mdc-theme-secondary: #1e1e44;
  --mdc-theme-on-surface: #eaf2fd;
}
.heading,
.heading2 {
  font-weight: 700;
  margin: 10px 0px;
  text-align: center;
}
.miniHeading {
  font-weight: 700;
  text-align: center;
  color: #4f4f4f;
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}
.mainSection {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  margin: 10px 0 0 0;
  width: 90%;
  margin: 20px 0px;
  gap: 20px;
}
.mainInnerSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-height: 1000px;
  height: 100%;
  margin: 200px 0px;
}
.mainInnerSection > .para {
  text-align: center;
}
.headerImg,
.headerImg2 {
  width: 800px;
}

.para {
  font-size: 24px;
  /* max-width: 800px; */
  width: 100%;
  white-space: normal;
  color: var(--mdc-theme-secondary);
}
.benefitsPara {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  display: flex;
  align-items: center;
  color: #4f4f4f;
}

.infoLight {
  background-color: white;
  color: #1e1e44;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 40px 0px;
  margin: 20px 0px;
}
.whoSection {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.whoCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 20px;
  width: 100%;
  max-width: 380px;
  font-size: 24px;
}
.whoCard > img {
  width: 75px;
}
.subHeading {
  font-weight: 700;
  font-size: 28px;
}
.feature {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px 0px;
  padding: 0px 20px;
  width: 95%;
}
.feature > img {
  order: 2;
}

.right {
  display: flex;
  align-items: center;
  justify-content: center;
}
.leftText {
  order: 0;
  width: 50%;
  text-align: left;
  padding: 0px 0px 0px 20px;
  order: 1;
}
.rightText {
  width: 50%;
  text-align: left;
  order: 1;
  padding: 0px 0px 20px 0px;
}
.footerSection {
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 30px 10px;
  color: black;
}

.footerPartOne {
  display: flex;
  gap: 20px;
}
.menuIcon {
  display: none;
}
.button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  max-width: 180px;
  height: 60px;
  background: #1904ff;
  border-radius: 7.06941px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: #ffffff;
  margin-top: 20px;
  cursor: pointer;
}

.benefitSection {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 90%;
  margin: 20px 0px;
}
.benefitInnerSection2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: flex-start;
  justify-items: center;
  min-width: 300px;
  grid-column-gap: 50px;
  grid-row-gap: 40px;
}
.benefitInnerSection {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: start;
  justify-items: center;
  text-align: center;
  min-width: 300px;
  grid-column-gap: 50px;
  grid-row-gap: 40px;
}
.workSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px;
}
.knowMoreSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  margin: 30px 0px;
  width: 90%;
}
.knowMoreInnerSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 15px;
}
.form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 10px;
}
.imageContainer {
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.worksImg {
  height: auto;
  max-width: 1000px;
  width: 1000px;
}
.howWeWorks {
  display: flex;
  gap: 30px;
  height: auto;
  max-width: 1400px;
  width: 100%;
  text-align: left;
}
/* Extra small devices (phones, 600px and down) */
.hompepageFeatureImage {
  display: block;
  margin: auto !important;
}
.featureGrid{
  text-align: center;
}
@media only screen and (max-width: 600px) {
  .feature {
    flex-direction: column-reverse;
  }
  .featureGrid {
    display: block;
    margin: 20px auto;
    text-align: center;
  }
  .hompepageFeatureImage {
    display: block;
    margin: auto !important;
  }
  .footerSection,
  .headerContainer {
    width: 95%;
    max-width: 400px;
  }
  .heading2 {
    font-size: 30px;
  }
  .miniHeading {
    font-size: 20px;
  }
  .heading,
  .headerHeading {
    font-size: 34px;
  }
  .para {
    font-size: 20px;
  }
  .para2 {
    font-size: 18px;
  }

  .footerSection {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 10px;
    padding: 10px;
  }

  .aboutText {
    display: none;
  }

  .infoLight {
    gap: 20px;
  }
  .whoCard {
    font-size: 20px;
  }
  .whoCard > img {
    width: 100px;
  }
  .whoSection {
    flex-direction: column;
    gap: 20px;
  }

  .rightText,
  .leftText {
    text-align: center;
    order: 1;
    width: 90vw;
    padding: 20px 0px;
  }
  .subHeading {
    width: 100%;
    margin: 20px 0px;
  }
  .form {
    grid-template-columns: 1fr;
    gap: 10px;
  }
  .mainInnerSection {
    margin: 50px 0px;
  }
  .imageContainer {
    align-items: center;
    justify-content: flex-start;
  }

  .headerImg {
    width: 300px;
  }
  #balanceConfirmationImage {
    width: 320px !important;
    max-width: 100% !important;
  }
  .headerImg2 {
    width: 250px;
  }
  .benefitInnerSection,
  .benefitInnerSection2 {
    grid-template-columns: 1fr;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .footerSection,
  .headerContainer {
    width: 90%;
    max-width: 700px;
  }
  .heading2 {
    font-size: 32px;
    margin: 10px 0px 30px 0px;
  }
  .miniHeading {
    font-size: 26px;
  }
  .heading,
  .headerHeading {
    font-size: 35px;
    margin: 10px 0px 30px 0px;
  }
  .para2 {
    font-size: 18px;
  }
  .para,
  .whoCard,
  .headerPara {
    font-size: 20px;
  }
  .feature {
    flex-direction: column-reverse;
    gap: 20px;
    width: 95%;
  }
  .rightText,
  .leftText {
    /* text-align: center; */
    /* order: 1; */
    width: 90vw;
    padding: 20px 0px;
  }
  .subHeading {
    width: 100%;
    margin: 15px 0px;
  }
  .whoSection {
    /* display: flex; */
    flex-direction: column;
    gap: 30px;
  }
  .moreMenu {
    display: none;
  }
  .benefitInnerSection,
  .benefitInnerSection2 {
    grid-template-columns: 1fr;
  }
  .mainInnerSection {
    margin: 100px 0px;
  }
  .headerImg {
    width: 400px;
  }
  .headerImg2 {
    width: 300px;
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .footerSection,
  .headerContainer {
    width: 750px;
    max-width: 760px;
  }
  .heading2 {
    font-size: 30px;
  }
  .miniHeading {
    font-size: 24px;
  }
  .heading,
  .headerHeading {
    font-size: 34px;
  }
  .mainHead {
    height: 600px;
  }

  .feature {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-between;
    margin: 40px 0px;
    padding: 0px 20px;
  }

  .right {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .leftText {
    /* order: 0; */
    width: auto;
    padding: 0px 0px 0px 20px;
  }
  .rightText {
    width: auto;
    text-align: left;
    /* order: 1; */
    padding: 0px 20px 0px 0px;
  }
  .whoSection {
    flex-direction: row;
  }
  /* .headerImg {
    width: 500px;
  } */
  .para2 {
    font-size: 20px;
  }
  .para,
  .whoCard {
    font-size: 24px;
  }
  .benefitInnerSection2 {
    grid-template-columns: 1fr 1fr;
  }
  .benefitInnerSection {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .worksImg {
    height: auto;
    max-width: 760px;
    width: 1000px;
  }
  /* .worksImg {
    background-image: url(../images/homepage/works.svg);
    background-size: contain;
    background-repeat: no-repeat;
    width: 90%;
    height: 300px;
  } */
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .footerSection,
  .headerContainer {
    width: 970px;
    max-width: 990px;
  }
  .mainSection {
    flex-direction: row;
  }
  .mainInnerSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    max-width: 700px;
    margin: 150px 0px;
  }
  .mainInnerSection > .para {
    text-align: left;
  }
  .heading2 {
    font-size: 42px;
    text-align: left;
  }
  .miniHeading {
    font-size: 30px;
    text-align: left;
  }
  .heading,
  .headerHeading {
    font-size: 46px;
    text-align: left;
  }
  .headerImg,
  .headerImg2 {
    width: 550px;
  }
  .feature {
    flex-direction: row;
    gap: 20px;
    width: 95%;
  }
  .feature > img {
    order: 1;
  }
  .rightText {
    order: 2;
  }
  .benefitSection {
    align-items: center;
    max-width: 1920px;
  }
  .knowMoreInnerSection {
    flex-direction: row;
  }
  .worksImg {
    height: auto;
    max-width: 980px;
    width: 1000px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .mainSection {
    max-width: 1920px;
    margin: 30px 0px 30px 0px;
  }
  .footerSection {
    width: 90%;
    max-width: 1920px;
  }
  .heading2 {
    font-size: 45px;
  }
  .miniHeading {
    font-size: 35px;
  }
  .heading {
    font-size: 56px;
  }
  .headerHeading {
    font-size: 52px;
  }
  .headerImg,
  .headerImg2 {
    width: 620px;
  }
  .worksImg {
    height: auto;
    max-width: 1400px;
    width: 100%;
  }
  .feature {
    gap: 60px;
    max-width: 1920px;
    width: 100%;
  }
}
/* 
@media only screen and (min-width: 1680px) {
  .worksImg {
    height: auto;
    max-width: 1900px;
    width: 90%;
  }
} */

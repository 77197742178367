.footerSection {
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 30px 10px;
  color: black;
}
.footerSection > .first {
  display: flex;
}
.footerSection > .second {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.footerSection > .third {
  display: flex;
}

@media only screen and (max-width: 600px) {
  .footerSection {
    width: 320px;
    max-width: 400px;
    align-items: center;
    text-align: center;
  }

  .footerSection {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 10px;
    padding: 10px;
  }
  .footerSection > .second {
    display: flex;
    flex-wrap: wrap;
  }
  .footerSection > .second > div {
    font-size: 15px;
    gap: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media only screen and (min-width: 600px) {
  .footerSection {
    width: 550px;
  }

  .footerSection > .second {
    display: flex;
    flex-wrap: wrap;
  }
  .footerSection > .second > div {
    font-size: 15px;
    gap: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media only screen and (min-width: 768px) {
  .footerSection {
    max-width: 872px;
    width: 90%;
  }
}

@media only screen and (min-width: 992px) {
  .footerSection {
    max-width: 1100px;
    width: 90%;
  }
}

@media only screen and (min-width: 1200px) {
  .footerSection {
    max-width: 1920px;
    width: 90%;
  }
}

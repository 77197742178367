.container {
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.heading {
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  text-align: left;
  color: #000000;
  margin: 10px 0px;
}
.subHeading {
  /* margin-bottom: 20px; */
  font-weight: 400;
  font-size: 16px;
  line-height: 34px;
  letter-spacing: 0.209909px;
  color: #4f4f4f;
}
.mainSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  width: 100%;
}
.formGroup {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  margin: 10px 0px 20px 0px;
}
.submitButton {
  background-color: var(--mdc-theme-primary);
  padding: 10px;
  min-width: 200px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  margin: 10px 0px;
  font-weight: 500;
  font-size: 15.0867px;
  color: #ffffff;
  align-self: flex-end;
}
.buttonGroup {
  width: 100%;
  margin: 10px 0px;
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.backButton {
  color: var(--mdc-theme-primary);
  font-weight: 600;
  cursor: pointer;
}
.bottomText {
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  text-align: center;
  color: #828282;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 40px 0px;
  width: 100%;
}
.container > img {
  width: 100px;
}

.heading {
  text-align: center;
  font-size: 40px;
  margin: 20px 0px;
  font-style: normal;
  font-weight: 700;
  font-size: 3rem;
  /* margin: 15px 2px; */
}

.rupeeSymbol {
  font-family: Roboto;
}

@media only screen and (max-width: 600px) {
  .heading {
    font-size: 2rem;
    margin-top: 40px;
  }
  .container {
    height: 50vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 900px) {
  .heading {
    font-size: 2rem;
  }
}

:root {
  --mdc-theme-primary: #2f80ed;
  --mdc-theme-secondary: #1e1e44;
  --mdc-theme-on-surface: #eaf2fd;
}

html {
  height: 100%;
}

.material-icons {
  font-family: "Material Icons" !important;
}

* {
  font-family: "Open Sans", sans-serif;
}
.LogoHead {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 7%;
}
body {
  overflow-y: scroll;
  min-height: 100%;
  margin: 0;
  padding: 0;
  background-color: #fff;
}
.content-max-widths-security {
  /* margin-left: 7%;
    margin-top: 0%; */
  display: block;
  margin: 0% auto;
  max-width: 1350px;
}
.content-max-widthSecurity {
  margin-left: 7%;
  margin-right: 7%;
}
.privacy {
  color: #1904ff;
  font-weight: 700;
  font-size: 36px;
  line-height: 54px;
  padding: 0px 0px 20px 0px;
}

.textPara {
  font-weight: 400;
  font-size: 18px;
  text-align: left;
  line-height: 28px;
  flex: none;
  order: 2;
  flex-grow: 0;
  color: var(--mdc-theme-secondary);
}
.termsHead,
.privacyHead {
  font-style: normal;
  font-weight: 600;
  font-size: 29px;
  line-height: 44px;
  margin: 30px 0px 10px 0px;
  color: var(--mdc-theme-secondary);
}
.termsHead {
  margin-top: 25px;
}

.nested {
  counter-reset: item;
}
hr {
  background-color: #eaf2fd !important;
}
.nestedItem {
  display: block;
}
.nestedItem:before {
  content: counters(item, ".") " ";
  counter-increment: item;
}
.securitySection {
  /* background: linear-gradient(to right, #1904ffee, #1904ffee, #1904ff),
    url("../../images/website/AboutPen.png"); */
  background-position: center;
  background-size: auto;
  background-repeat: no-repeat;
  height: 285px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 90%;
}
.aboutHead {
  font-style: normal;
  font-weight: 700;
  font-size: 62px;
  line-height: 93px;
  /* identical to box height */
  color: #ffffff;
  padding-top: 3.5%;
  text-align: center;
}

#terms {
  margin-bottom: 3%;
}
.aboutSection {
  /* background-image: url(../../images/website/About.png); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 250px;
}
.aboutHead {
  font-style: normal;
  font-weight: 700;
  font-size: 62px;
  line-height: 93px;
  /* identical to box height */
  color: var(--mdc-theme-secondary);
  padding: 10px;
}
.privacyOl {
  padding-left: 15px;
  line-height: 28px;
  color: var(--mdc-theme-secondary);
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
}
.termsInsideol {
  padding-left: 0px;
  color: var(--mdc-theme-secondary);
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
}
.aboutSectionDiv {
  display: flex;
  justify-content: center;
  align-items: center;
}
.aligncenter {
  box-sizing: border-box;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
  align-items: center !important;
  flex-direction: row;
  margin-top: 0px;
  width: calc(100% + 16px);
  margin-left: none !important;
}
.footerPrivacySection {
  display: flex;
  align-items: center;
  justify-content: flex-start !important;
  flex-direction: row;
  gap: 10%;
}
.footerRightSection {
  display: flex;
  align-items: center;
  justify-content: flex-end !important;
  flex-direction: row;
  gap: 10%;
}
@media only screen and (min-width: 300px) and (max-width: 600px) {
  #terms {
    margin-bottom: 40px;
  }
  .LogoHead {
    justify-content: center;
    padding-left: 0px;
  }
  .aboutHead {
    font-size: 42px;
    line-height: 60px;
    text-align: center;
  }
  .textPara {
    font-size: 16px;
  }
  .privacyOl,
  .termsInsideol {
    font-size: 16px;
  }
  .privacyOl {
    padding-left: 0px;
  }
  .termsHead,
  .privacyHead {
    font-size: 21px;
    line-height: 36px;
  }
  .termsHead {
    font-size: 23px;
    line-height: 36px;
  }
  .aligncenter {
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }

  .footerPrivacySection {
    justify-content: center !important;
  }
  .footerRightSection {
    justify-content: center !important;
  }
}

:root {
  --mdc-theme-primary: #1904ff !important;
  --mdc-theme-secondary: #1e1e44 !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.mdl-shadow--2dp {
  box-shadow: none !important;
  margin-top: -20px !important;
}

.logo {
  display: block;
  margin: 100px auto;
}
.gstInputCard {
  box-shadow: none !important;
  max-width: 600px;
  display: block;
  margin: 4% auto;
}
.gstContinuebutton {
  float: right;
  margin-top: 30px !important;
  width: 143px;
  height: 56px;

  /* BG Button */

  background: #f5f4ff;
  /* Prim */

  border: 2px solid var(--mdc-theme-primary);
  border-radius: 4px;
}

.locationConfirmContinueButton {
  border: 2px solid var(--mdc-theme-primary);
  border-radius: 4px;
  background: #f5f4ff;
  color: #1904ff;
}

.gstInput {
  width: 100%;
}
.cardAddr {
  display: inline-flex;
  align-items: center;
  gap: 15px;
}
.successMessage {
  display: inline-flex;
  gap: 20px;
  align-content: center;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 15px;
  margin-bottom: 15px;
}
.gstLookupError {
  color: red;
  text-align: center;
}

.successMessages {
  /* background: url(./images/successMessage.png); */
  min-height: 101.7vh;
  background-repeat: no-repeat;
  background-size: cover;
}
.loginScreenVerify {
  /* background: url(./images/verify.png); */
  min-height: 101.7vh;
  background-repeat: no-repeat;
  background-size: cover;
}
.gstInputImage {
  /* background: url(./images/gst.png); */
  min-height: 101.7vh;
  background-repeat: no-repeat;
  background-size: cover;
}

.successLogo {
  margin: 0 auto;
  display: block;
  width: 200px;
}
.successSection {
  margin-top: 10%;
}

.loginPageLogo {
  width: 150px;
  display: block;
  margin: 10% auto 2% auto;
}

.successTick {
  width: 150px;
  display: block;
  margin: 6% auto;
}

.successDesc {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 54px;
  text-align: center;
  letter-spacing: 0.10287px;
  color: #1e1e44;
}
#successCard {
  background: #f5f4ff;
  border-radius: 8px;
  max-width: 600px;
  margin: 10px auto;
}
.welcomeCompany {
  font-family: "Open Sans", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 54px;
  margin-top: -20px;
  /* identical to box height */

  text-align: center;

  /* Sec */

  color: #1e1e44;
}
.gstNumber {
  display: flex;
  justify-content: center;
  gap: 15px;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: var(--mdc-theme-secondary);
}

.editIcon {
  color: var(--mdc-theme-primary);
}
.selectCompany {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: var(--mdc-theme-secondary);
  text-align: center;
}

.location {
  color: var(--mdc-theme-primary);
}

#locationCard {
  max-width: 500px;
  height: 91px;
  background: #ffffff;
  box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.09);
  border-radius: 6px;
  display: inline-flex;
}
.locationCardContent {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;

  /* Sec */

  color: #1e1e44;
}

.cardAddr {
  padding-left: 15px;
  padding-right: 15px;
}
.branchSelectorImage {
  /* background-image: url("./images/location.png"); */
  min-height: 101.7vh;
  background-repeat: no-repeat;
  background-size: cover;
}
.loactionCards {
  margin-left: 5%;
}
.gstInputHead {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 54px;
  /* identical to box height */

  text-align: center;

  /* Sec */

  color: var(--mdc-theme-secondary);
}
.gstInputContent {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 24px;
  /* identical to box height */

  text-align: center;

  /* Sec */

  color: #1e1e44;
}
.logoLogin {
  width: 150px;
  display: block;
  margin: 15% auto !important;
}
.mdl-card {
  margin: 5% auto !important;
}
.locationConfirmButton {
  float: right;
  margin-top: 5%;
  display: inline-flex;
  gap: 20px;
}

@media only screen and (min-width: 300px) and (max-width: 600px) {
  .locationConfirmButton {
    float: right;
    margin-top: 5%;
  }
  #successCard {
    width: 90%;
  }
  .successDesc {
    font-size: 24px;
    line-height: 45px;
    padding-left: 5%;
    padding-right: 5%;
  }
  .selectCompany {
    font-size: 15px;
    padding-left: 5%;
    padding-right: 5%;
  }

  .companyName {
    font-size: 21px;
  }

  .successMessages {
    display: none;
  }
  /*  */
  .loginScreenVerify {
    display: none;
  }
  .gstContinuebutton {
    display: block !important;
    margin: 30px auto !important;
    text-align: center;
    float: none;
    width: 200px;
  }
  .gstInput {
    width: 90%;
    margin-left: 5% !important;
    margin-right: 5% !important;
  }
  .branchSelectorImage {
    display: none;
  }
  .welcomeCompany {
    font-size: 25px;
    line-height: 46px;
  }
  .gstInputImage {
    display: none;
  }
}

.mdl-button--raised.mdl-button--colored,
.gstContinuebutton,
.locationConfirmContinueButton {
  border: 1px solid #1904ff !important;
  background-color: #f5f4ff !important;
  color: #1904ff !important;
}

.loadingCircle {
  margin: auto;
  display: block;
  text-align: center;
  color: #1904ff !important;
  z-index: 1000 !important;
  /* position: absolute; */
  opacity: 5;
}

.errorpageLogo {
  display: block;
  margin: 3% auto;
  width: 150px;
  margin-top: 6% !important;
}
.errorImage {
  display: block;
  margin: 2% auto;
}
.defaultLocation {
  max-width: 600px;
  display: block;
  margin: 0 auto;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.buttonGroup {
  margin: 20px 0px;
}
.inputField {
  margin: 10px 0px;
}
.subHeading {
  margin: 10px 0px;
}
.formContainer {
  width: 90%;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  gap: 30px;
}

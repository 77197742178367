.addPeopleHeaderContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}
.addPeopleHeader {
  font-style: normal;
  font-weight: 800;
  font-size: 60px;
  text-align: center;
  margin: 20px 0px;
}
.addPeopleSubHeader {
  max-width: 800px;
  font-size: 35px;
  text-align: center;
}

@media only screen and (min-width: 300px) and (max-width: 600px) {
  .addPeopleHeader {
    font-size: 40px;
  }
  .addPeopleSubHeader {
    font-size: 20px;
  }
}

.adCampaignHeader {
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
  height: 120px;
  
}

.adCampaignLogo {
  display: block;
  margin: 15px auto;
  margin-left: 20px;
  width: 175px;
}

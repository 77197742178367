.headerContainer {
  height: 80px;
  padding: 30px 10px 10px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.linkSection {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}
.aboutText {
  font-weight: 600;
  font-size: 24px;
  position: relative;
  cursor: pointer;
  color: #1e1e44 !important;
  text-decoration: none;
}
.link {
  font-weight: 600;
  font-size: 20px;
  cursor: pointer;
  color: #1e1e44;
  text-decoration: none;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .headerContainer {
    width: 95%;
    max-width: 400px;
  }

  .aboutText {
    display: none;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .headerContainer {
    width: 90%;
    max-width: 400px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
/* @media only screen and (min-width: 768px) {
  .headerContainer {
    width: 750px;
    max-width: 760px;
  }
} */

/* Large devices (laptops/desktops, 992px and up) */
/* @media only screen and (min-width: 992px) {
  .headerContainer {
    width: 970px;
    max-width: 990px;
  }
} */

/* Extra large devices (large laptops and desktops, 1200px and up) */
/* @media only screen and (min-width: 1200px) {
  .headerContainer {
    width: 90%;
    max-width: 1920px;
  }
} */

.headingContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.heading {
  font-size: 28px;
}

.formField {
  width: auto !important;
  max-width: 100% !important;
}
.formGroup {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.formGroup > input {
  background: transparent !important;
}

.submitButton {
  background-color: var(--mdc-theme-primary);
  color: white;
  padding: 10px;
  width: 100%;
  border: none;
  border-radius: 6px;
  font-size: 20px;
  cursor: pointer;
}
.submitButtonDisabled {
  background-color: var(--mdc-theme-on-surface);
  color: white;
  padding: 10px;
  width: 100%;
  border: none;
  border-radius: 6px;
  font-size: 20px;
}

.subHeading {
  margin: 10px 0px;
}

.backButton {
  color: var(--mdc-theme-primary);
  font-weight: 600;
  cursor: pointer;
}
.submitOtpButton {
  background-color: var(--mdc-theme-primary);
  color: white;
  padding: 8px 40px;
  border: none;
  border-radius: 6px;
  font-size: 20px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  border-radius: 35px;
  cursor: pointer;
}

.buttonGroup {
  width: 100%;
  margin-top: 10px;
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

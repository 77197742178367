:root {
  --mdc-theme-primary: #1904ff;
  --mdc-theme-secondary: #1e1e44;
  --mdc-theme-on-surface: #eaf2fd;
}
* {
  font-family: "Open Sans";
}
.balanceConfirmationSection {
  display: block;
  max-width: 400px;
  margin: 20px auto;
}
.balanceConfirmationHead {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 34px;
  letter-spacing: 0.209909px;
  color: #000000;
}
.balanceConfirmationPara {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  text-align: justify;

  /* Gray 1 */

  color: #333333;
}
.contentDiv {
  margin: 0px 10px;
}
.balanceConfirmTable {
  border-collapse: collapse;
  border: 1px solid #4f4f4f;
}
.tableHeadRow > th,
.tableHeadRow > td {
  border: 1px solid black;
  padding: 8px;
  border-top: none;
}

.tableHead {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 13px !important;
  line-height: 18px;
  padding: 15px;
  align-items: center;
  color: #000000;
}

.tableContent {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 13.3822px !important;
  line-height: 18px;

  align-items: center;

  color: #4f4f4f;
  padding: 10px 5px;
}
.balanceConfirmFooter {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
  color: #333333;
  margin-top: 20px;
}
.balanceConfirmButtonDiv {
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.balanceConfirmButton {
  width: 160px;
  height: 39px;
  background: #1904ff;
  border-radius: 4px;
  align-items: center;
  padding: 10px;
  gap: 10px;
  font-weight: 600;
  font-size: 16px;
  line-height: 34px;
  border: 1px solid #1904ff;
}

.declarationHeader {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}
.declarationContent {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #333333;
}
.confirmBalanceButton {
  width: 90%;
  margin-left: 5% !important;
  margin-right: 5% !important;
  margin-top: 5% !important;
  height: 45px;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 34px !important;

  letter-spacing: 0.209909px !important;

  color: #ffffff;
}
.declarationFooter {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: -0.12px;

  /* Gray 3 */

  color: #828282;
  margin-top: 20px;
}

.confirmBalanceButtonDiv {
  /* margin-top: 30%; */
  position: absolute;
  bottom: 5%;
  max-width: 400px;
  transform: translate(0%, 0px);
}

.invoiceHeadHeading {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */

  align-items: center;

  /* Gray 1 */

  color: #333333;
}

.row {
  display: flex;
  flex-wrap: wrap;
}
.iconContainer {
  flex: 50% 1;
  text-align: right;
  justify-content: center;
  align-items: center;
  display: flex;
  gap: 10px;
}
.details {
  flex: 50%;
  text-align: right;
}

.adminAddBUtton {
}

.referContent {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;

  /* Gray 1 */

  color: #333333;
}
.earlyPaymentRequestButton {
  width: 315px;
  height: 55px;
  border-radius: 15px;
  display: block;
  /* margin: 20px auto; */
  margin-top: 30px;
  font-size: 17px !important;
  font-weight: 500;
}
.formHeader {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 34px;
  /* identical to box height, or 210% */

  letter-spacing: 0.209909px;

  /* Gray 2 */

  color: #4f4f4f;
}
.referSuccessImage {
  display: block;
  margin: auto;
}
.referButton {
  background: #1904ff;
  border-radius: 4px !important;
  width: 100%;
  /* margin-left: 5% !important;
  margin-right: 5% !important; */
  height: 41px !important;
  margin-top: 10% !important;
  position: absolute;
  width: 100%;
  bottom: 5%;
  transform: translate(0px, 0px);
  max-width: 400px;
}

@media (max-width: 600px) {
  .textFieldContainer {
    width: 100% !important;
    /* margin-left: 5% !important;
    margin-right: 5% !important; */
  }
}

.summaryView {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: inherit;
  min-height: 100vh;
  position: relative;
  padding: 0px 10px;
}
.backSection {
  align-self: flex-start;
  display: flex;
  align-items: flex-start;
}
.leftArrow {
  width: 24px;
  height: 24px;
  display: inline;
  margin-right: 10px;
  cursor: pointer;
}
.rowSeparated {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #4f4f4f;
  margin: 8px 0px;
}

.rowHeading {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #333333;
}

.rowLeft {
  width: 100%;
  text-align: left;
  border-spacing: 0 1rem;
  border: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #4f4f4f;
  display: flex;
}

.rowLeft > tbody > tr > td {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #4f4f4f;
  max-width: 180px;
}
.fixedWithContainer {
  width: 55%;
  display: flex;
  gap: 10px;
  margin: 8px 0px;
}
.iconContainer {
  display: flex;
  align-items: center;
  gap: 5px;
}

.container {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  min-width: 320px;
  max-width: 388px;
  background-color: white;
  border: none;
  border-radius: 6px;
  padding: 20px;
  z-index: 100;
}
.modalCloseButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 7px;
  width: 57px;
  height: 45px;
  background: #ffffff;
  border: 1px solid #1904ff;
  border-radius: 7.2px;
  cursor: pointer;
}
.hr {
  width: 100%;
  height: 0px;
  border: 1px solid #f2f2f2;
  margin: 10px 0px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  min-height: 100vh;
  width: 100%;
  font-family: "Open Sans";
  font-style: normal;
  font-size: 18px;
  line-height: 25px;
  color: #1e1e44;
}

.headerContainer {
  /* height: 80px; */
  padding: 30px 10px 10px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .headerContainer {
    width: 100%;
    max-width: 400px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .headerContainer {
    width: 100%;
    max-width: 400px;
  }
}

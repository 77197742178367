:root {
  --mdc-theme-primary: #1904ff;
  --mdc-theme-secondary: #1e1e44;
  --mdc-theme-on-surface: #eaf2fd;
}

/* th,
td {
  font-size: 28px !important;
}

th {
  background: #f5f4ff !important;
} */

/* .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input{
    background: #f5f4ff !important;
    color: blue !important;
    border-color:blue !important;
}

.css-1q1u3t4-MuiTableRow-root{
    outline: blue !important;
} */

.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
  color: blue !important;
}

.css-41abqd-MuiTableContainer-root {
  width: 100%;
  overflow-x: auto;
  max-height: 600px !important;
}

.mindue {
  font-family: Arial, Helvetica, sans-serif !important;
  margin: 2% 0;
}

.css-1ex1afd-MuiTableCell-root,
.css-177gid-MuiTableCell-root {
  border: none !important;
}

.amountheading {
  display: flex;
  align-items: center;
  gap: 15px;
  text-decoration: none;
  color: var(--mdc-theme-secondary);
  width: auto;
}

.link {
  text-decoration: none;
  color: #1e1e44;
  display: contents;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  font: inherit;
  letter-spacing: inherit;
  color: blue;
  padding: 4px 0 5px;
  /* background: #f5f4ff !important; */
  border-radius: 5px;
  box-sizing: content-box;
  background: none;
  height: 1em;
  margin: 0;
  -webkit-tap-highlight-color: blue;
  display: block;
  min-width: 0;
  width: 100%;
  -webkit-animation-name: mui-auto-fill-cancel;
  animation-name: mui-auto-fill-cancel;
  -webkit-animation-duration: 10ms;
  animation-duration: 10ms;
  padding: 16.5px 14px;
}

CalendarTodayIcon {
  color: #1904ff !important;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: 2px solid blue !important;
}

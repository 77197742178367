.is-sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  box-shadow: 0 2px 24px 0 rgb(0 0 0 / 15%);
  background-color: #ffffff !important;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  padding-top: 0px;
  padding-bottom: 0px;
}
.headerContainer {
  height: 80px;
  padding: 30px 10px 10px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.linkSection {
  display: flex;
  justify-content: space-between;
  gap: 35px;
}

.aboutText {
  font-weight: 600;
  font-size: 20px;
  position: relative;
  cursor: pointer;
  color: #1e1e44 !important;
  text-decoration: none;
}
.link {
  font-weight: 600;
  font-size: 17px;
  cursor: pointer;
  color: #1e1e44;
  text-decoration: none;
}

.menuIcon {
  display: none;
}
.dropdown {
  z-index: 100;
  padding: 0;
  width: 100%;
  max-width: 300px;
  background-color: white;
  box-shadow: 0px 4px 15px 5px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  font-size: 21px;
  border-radius: 5px;
}
.dropdown > a {
  margin: 0px 0px 0px 0px;
  padding: 20px;
}
.dropdown > a:hover {
  background-color: rgb(226, 223, 223);
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .headerContainer {
    width: 95%;
    max-width: 400px;
  }
  .dropdown {
    text-align: center;
    box-shadow: none;
  }
  .aboutText {
    display: none;
  }
  .dropdownAboutText {
    font-weight: 600;
    font-size: 24px;
    position: relative;
    cursor: pointer;
    color: #1e1e44;
  }
  .menuIcon {
    display: inline-block;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .moreMenu {
    position: absolute;
    top: 90px;
    left: 0%;
    color: #1e1e44;
    height: auto;
    background-color: white;
    box-shadow: 0px 5px 18px #888888;
    width: 100%;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    padding: 20px;
    transition: all 0.3s ease-in-out;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .headerContainer {
    width: 90%;
    max-width: 700px;
  }
  .moreMenu {
    display: none;
  }
  .dropdown {
    position: absolute;
    width: 400px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .headerContainer {
    width: 750px;
    max-width: 760px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .headerContainer {
    width: 970px;
    max-width: 990px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .headerContainer {
    width: 90%;
    max-width: 1920px;
  }
}

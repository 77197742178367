:root {
  --mdc-theme-primary: #1904ff;
  --mdc-theme-secondary: #1e1e44;
  --mdc-theme-on-surface: #eaf2fd;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}
.aboutText {
  text-decoration: underline;
  font-weight: 600;
  color: var(--mdc-theme-primary);
  font-size: 24px;
  margin: 0px 10px 10px 0px;
  text-underline-offset: 10px;
}

.mainSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  /* flex: 1; */
  width: 100%;
  gap: 10px;
  margin: 2vh 0px;
}
.aboutSection {
  background: url("../images/about/missionbg.svg");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  margin: 40px 0px;
}
.pageHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0px 0px 0px;
  width: 100%;
}
.heading {
  color: black;
  font-weight: 700;
  font-size: 65px;
  text-align: center;
  width: 70%;
}
.subHeading {
  color: black;
  font-weight: 600;
  font-size: 42px;
}
.para,
.missionPara {
  white-space: normal;
  color: black;
  font-size: 22px;
  font-weight: 400;
  color: var(--mdc-theme-secondary);
}
.missionPara {
  padding: 0px 50px;
  font-size: 20px;
}

.headerContainer {
  height: 100px;
  padding: 40px 10px 20px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.missionContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 25px;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  padding: 60px;
  width: 100%;
}
.missionHeading {
  font-weight: 700;
  font-size: 65px;
  text-align: center;
  max-width: 894px;
  color: white;
}
.infoLight {
  background-color: white;
  color: #1e1e44;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 40px 0px;
  margin: 20px 0px;
}
.whoCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 20px;
  width: 100%;
  max-width: 380px;
  font-size: 24px;
}
.whoCard > img {
  width: 75px;
}

.features {
  margin: 10px 0px;
  display: grid;
  grid-template-columns: auto 1fr 10fr;
  gap: 20px;
  align-items: center;
}
.features > .first {
  background-color: #1904ff;
  width: 10px;
  height: 100%;
}
.features > .second {
  color: #1904ff;
  font-size: 24px;
  font-weight: 600;
}
.features > .second > span {
  color: #1904ff;
  font-size: 40px;
  font-weight: 600;
}
.features > .third {
  background: #ffffff;
  box-shadow: 0px 4px 8px 4px rgba(0, 0, 0, 0.04);
  padding: 40px;
  font-size: 22px;
}
.features > .third > .subHeading {
  margin-bottom: 10px;
}

.moreSection {
  background: url("../images/about/moreimage.webp");
  width: 100%;
  height: 420px;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;
}

.moreTextSection > .subHeading {
  margin: 5px 0px 15px 0px;
}

.moreTextSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ce;
  background-color: white;
  padding: 20px;
  font-size: 20px;
  width: 90%;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  max-height: 80%;
  transform: translateY(100px);
  text-align: center;
  box-shadow: 0px 4px 15px 5px rgba(0, 0, 0, 0.08);
  border-radius: 21px;
}

.founderSection {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.founderSection > img {
  max-width: 230px;
  width: 100%;
}

.footerSection {
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 30px 10px;
  color: black;
}
.footerSection > .first {
  display: flex;
}
.footerSection > .second {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.footerSection > .third {
  display: flex;
}
.bummer {
  height: 0px;
  display: none;
}
.menuIcon {
  display: none;
}
.whoSection {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.headingWho {
  font-weight: 700;
  margin: 10px 0px;
  text-align: center;
  font-size: 42px;
  line-height: 57px;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .mainSection,
  .footerSection {
    width: 320px;
    max-width: 400px;
    align-items: center;
    text-align: center;
  }
  .infoLight {
    gap: 20px;
  }
  .whoCard {
    font-size: 20px;
  }
  .whoCard > img {
    width: 100px;
  }
  .aboutText {
    display: none;
  }
  .menuIcon {
    display: inline-block;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0px 10px 20px 0px;
  }
  .moreMenu {
    position: absolute;
    top: 100px;
    left: 0%;
    height: 150px;
    /* border: 1px solid black; */
    background-color: white;
    box-shadow: 0px 5px 18px #888888;
    width: 100%;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    transition: all 0.3s ease-in-out;
  }
  .heading {
    width: 100%;
  }
  .missionHeading,
  .heading {
    font-size: 36px;
  }
  .missionContainer {
    padding: 40px;
  }
  .para,
  .missionPara {
    font-size: 16px;
  }
  .missionPara {
    padding: 10px 0px;
  }
  .headerContainer {
    width: 100%;
    /* flex-direction: column; */
    align-items: center;
    justify-content: space-between;
  }
  .headerContainer > a {
    text-align: center;
  }
  .footerSection {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 10px;
    padding: 10px;
  }
  .footerSection > .second {
    display: flex;
    flex-wrap: wrap;
  }
  .footerSection > .second > div {
    font-size: 15px;
    gap: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .features {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }
  .features > .first {
    display: none;
  }
  .whoSection {
    flex-direction: column;
    gap: 20px;
  }
  .features > .second {
    width: 50px;
  }
  .features > .third {
    background: #ffffff;
    box-shadow: none;
    padding: 20px;
    font-size: 16px;
  }
  .moreSection {
    background-size: cover;
  }
  .moreTextSection {
    transform: translateY(200px);
    max-height: 200%;
  }
  .subHeading {
    font-size: 25px;
  }
  .moreSectionDesc {
    font-size: 16px;
  }
  .founderSection > img {
    max-width: 280px;
  }
  .bummer {
    height: 300px;
    display: block;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .mainSection,
  .footerSection,
  .headerContainer {
    width: 550px;
  }
  .whoSection {
    flex-direction: column;
    gap: 30px;
  }
  .heading {
    width: 90%;
  }
  .missionHeading,
  .heading {
    font-size: 32px;
  }
  .subHeading {
    font-size: 32px;
  }
  .whoCard {
    font-size: 20px;
  }

  .moreSectionDesc {
    /* font-size: 18px; */
    padding: 2% 0;
  }
  .moreTextSection {
    transform: translateY(150px);
    max-height: 150%;
  }
  .bummer {
    height: 200px;
    display: block;
  }
  .footerSection > .second {
    display: flex;
    flex-wrap: wrap;
  }
  .footerSection > .second > div {
    font-size: 15px;
    gap: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .moreMenu {
    display: none;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .whoSection {
    flex-direction: row;
  }
  .whoCard {
    font-size: 24px;
  }
  .mainSection,
  .footerSection,
  .headerContainer {
    width: 750px;
  }
  .heading,
  .missionHeading {
    font-size: 40px;
  }
  .para,
  .features > .third,
  .moreSectionDesc {
    font-size: 18px;
  }
  .missionPara {
    font-size: 18px;
  }
  .moreTextSection {
    transform: translateY(80px);
    max-height: 150%;
  }
  .bummer {
    height: 100px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .mainSection,
  .footerSection,
  .headerContainer {
    width: 970px;
  }
  .heading,
  .missionHeading {
    font-size: 46px;
  }
  .para {
    font-size: 22px;
  }
  .features > .third,
  .moreSectionDesc {
    font-size: 18px;
  }
  .missionPara {
    font-size: 18px;
  }
  .bummer {
    height: 200px;
    display: none;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .mainSection,
  .footerSection,
  .headerContainer {
    max-width: 1400px;
    width: 95%;
  }
  .heading {
    width: 70%;
  }
  .heading,
  .missionHeading {
    font-size: 56px;
  }
  .founderSection {
    justify-content: space-around;
  }
  .founderSection > img {
    max-width: 260px;
    width: 100%;
  }
  .bummer {
    display: none;
  }
}
